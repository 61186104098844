<template>
  <v-container
    fluid
  >

    <exercisePlayer 
      v-if="!dialogSummary"
      ref="exercisePlayer"
      :landscape="landscape"      
      :width="workspaceWidth"
      :height="workspaceHeight"
      :totalQuestions="questions"
      @playedAudio="playedAudio=true"
      @clearedAudio="playedAudio=false"
      @showTick="showTick"
      @questionCompleted="questionCompleted"
      @questionReady="questionReady"
      @allQuestionsCompleted="allQuestionsCompleted"
      @loadExercise="loadExercise"
    ></exercisePlayer>

    <div 
      v-if="question.showTick && question.correct"
      :style="'z-index:2000; position:absolute; left: ' + tick.left +'px; top:' + tick.top + 'px;'"      
    >
      <v-img
        :max-width="tick.width + 'px'"
        src="@/assets/tick_correct.png"
        @click="nextQuestion"
      ></v-img>
    </div>

    <div 
      v-if="question.showTick && !question.correct"
      :style="'z-index:2000; position:absolute; left: ' + tick.left +'px; top:' + tick.top + 'px;'"      
    >
      <v-img
        :max-width="tick.width + 'px'"
        src="@/assets/tick_incorrect.png"
        @click="nextQuestion"
      ></v-img>
    </div>

    <div 
      v-if="landscape && !dialogSummary"
      class="disable-dbl-tap-zoom sidebar"
    >

      <v-btn         
        class="disable-dbl-tap-zoom mx-1 my-1" 
        color="primary"        
        style="displabottom: 0px"
        @click="backToLesson"
        small
      >
        <v-icon>mdi-exit-run</v-icon>
      </v-btn>

      <v-progress-circular
        v-if="questions > 0"
        :rotate="270"
        :size="75"
        :width="15"
        :value="(questionsTotal / questions) * 100"
        color="primary"
      >
      {{questions}}
      </v-progress-circular>

      <v-chip
        class="ma-2"
        :color="scoreColor"
        :text-color="scoreTextColor"
      >
        {{questionsCorrect}} / {{questionsTotal}}
      </v-chip>

      <v-btn
        class="disable-dbl-tap-zoom mx-1 my-1" 
        small
        @click="replayAudio"
      >
        <v-icon>mdi-refresh</v-icon>    
      </v-btn>

      <v-btn 
        class="disable-dbl-tap-zoom mx-1 my-1"    
        small                
        @click="showAnswer"               
      >
        <v-icon>mdi-help-box</v-icon>
      </v-btn>

    </div>

    <div
      v-if="!landscape && !dialogSummary"
      class="disable-dbl-tap-zoom bottombar"
    >

      <v-btn 
        class="disable-dbl-tap-zoom mx-1"  
        color="primary"       
        @click="backToLesson"
        small
      >
        <v-icon>mdi-exit-run</v-icon>
      </v-btn>

      <v-progress-circular
        v-if="questions > 0"
        :rotate="270"
        :size="40"
        :width="5"
        :value="(questionsTotal / questions) * 100"
        color="primary"
      >
      {{questions}}
      </v-progress-circular>

      <v-chip
        class="ma-2"
        :color="scoreColor"
        :text-color="scoreTextColor"
      >
        {{questionsCorrect}} / {{questionsTotal}}
      </v-chip>

      <v-btn
        class="disable-dbl-tap-zoom mx-1" 
        small
        @click="replayAudio"
      >
        <v-icon>mdi-refresh</v-icon>    
      </v-btn>

      <v-btn 
        class="disable-dbl-tap-zoom mx-1"   
        small                
        @click="showAnswer"               
      >
        <v-icon>mdi-help-box</v-icon>
      </v-btn>

    </div>

    <v-dialog
      v-model="dialogSummary"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-img
            alt="Metricforms"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
          ></v-img>
        </v-card-title> 

        <v-card-text>

            <br /><v-progress-linear
              :color="scoreColor"
              striped
              height="25"
              :value="(questionsCorrect / questionsTotal) * 100"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>          
            </v-progress-linear><br />
            Total Questions: <strong>{{ questionsTotal }}</strong><br />
            Total Correct: <strong>{{ questionsCorrect }}</strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>        
          <v-btn
            color="primary"
            text
            @click="backToLesson();"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  //import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  //const CourseRepository = RepositoryFactory.get('course');

  export default {
    name: 'Exercise',

    components: {
      exercisePlayer: () => import("@/components/ExercisePlayer"),
    },

    data: () => ({
      questions: 0,
      questionsCorrect: 0,
      questionsTotal: 0,
      scoreColor: '',
      scoreTextColor: '',
      course: {},
      courseItems: [],
      selectedLesson: {},
      currentLessonIndex: -1,  
      workspaceWidth: 1280,  
      workspaceHeight: 768,      
      playedAudio: false,
      touchDevice: false,
      landscape: true,
      renderLandscape: true,
      contentLandscape: true,
      dialogSummary: false,
      tick: {
        width: 96,
        top: 0,        
        right: 10,    
      },
      question: {
        completed: false,
        correct: false,
        showTick: false
      },
    }),

    created() {
      document.addEventListener("touchmove", this.preventTouchMoveBehavior, {passive: false});
      window.addEventListener('resize', this.onWindowResize, false);         
    },

    destroyed() {
      document.removeEventListener("touchmove", this.preventTouchMoveBehavior);
      window.removeEventListener('resize', this.onWindowResize, false);         
    },

    mounted() {   
      this.questions = parseInt(this.$route.params.questions);    
      this.touchDevice = ('ontouchstart' in window || navigator.maxTouchPoints);
      this.refreshLayout();        
      //this.loadExercise();  
    },

    methods: {

      backToLesson() {
        if (this.$refs.exercisePlayer) {
          this.$refs.exercisePlayer.clearAudio();
        }        
        this.$router.go(-1);
      },

      isTouchScreenDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
      },

      async loadExercise() {

        this.$refs.exercisePlayer.loadExercise(this.$route.params.lessonwidgetid, this.$route.params.exerciseid);

        /*const { data } = await CourseRepository.getExercise(this.$route.params.lessonwidgetid, this.$route.params.exerciseid);
        if (data.success) {          
          this.$refs.exercisePlayer.loadExercise(data.data.exercise);
        } else {          
          alert(data.message);
        }*/
      },

      nextQuestion() {   
        this.question.completed = false;
        this.question.showTick = false;
        this.$refs.exercisePlayer.nextQuestion();
      },      

      onKeyPress(button) {
          //alert(button);
          switch (button) {                  
            case '{shift}':
            case '{lock}':
              this.handleShift();
              break;
            case '{bksp}':
              this.$refs.exercisePlayer.handleKey(8);
              break;
            case '{enter}':
              this.$refs.exercisePlayer.handleKey(13);
              break;
            case '{space}':
              this.$refs.exercisePlayer.handleKey(32);
              break;                    
            default:
              if (button.substr(0, 1) != '{') {
                this.$refs.exercisePlayer.handleKey(button.charCodeAt(0));
              }                    
          }
      },

      onWindowResize() {   
        if (this.question.completed) {
          this.refreshLayout(false);     
        } else {
          this.refreshLayout(true);     
        }
        
      },

      preventTouchMoveBehavior(e) {
          e.preventDefault();           
      },

      questionCompleted(correct, marks, total, usedHelp) { // eslint-disable-line   
        this.question.completed = true;     
        this.questionsTotal++;
        if (correct && !usedHelp) {
          this.questionsCorrect++;
        }
        this.scoreColor = 'grey';
        this.scoreTextColor = 'black';
        if (this.questionsTotal > 0) {
          const percentage = this.questionsCorrect / this.questionsTotal;
          if (percentage >= 0.85) {
            this.scoreColor = 'green';
            this.scoreTextColor = 'white';
          } else if (percentage < 0.5) {
            this.scoreColor = 'red';
            this.scoreTextColor = 'white';
          }
        }

        this.refreshLayout(false);

      },

      questionReady() {
        //if ((this.questions > 0) && (this.questionsTotal > this.questions)) {
        //  this.dialogSummary = true;
        //}
        this.question.completed=false; 
        this.question.showTick=false;
        this.refreshLayout(true);           
      },

      allQuestionsCompleted() {
        this.dialogSummary = true;
        this.question.showTick=false;
        this.refreshLayout(true);         
      },

      refreshLayout(onReady = false) {

        this.landscape = true; 
        this.renderLandscape = true;
        if (window.innerWidth < window.innerHeight) {                  
          this.landscape = false;   
          this.renderLandscape = false;     
        }

        if (onReady && (this.$refs.exercisePlayer.renderLandscape != this.renderLandscape)) {
          this.landscape = this.$refs.exercisePlayer.renderLandscape;
        }

        let scale = 1.0;

        // landscape

        if (this.landscape) {

          // landscape

          let availableWidth = window.innerWidth - 120;
          let availableHeight = window.innerHeight - 100;
          const xScale = availableWidth / 1280;
          const yScale = availableHeight / 768;

          if (xScale < yScale) {
            scale = xScale;
            this.workspaceWidth = availableWidth;
            this.workspaceHeight = 768 * scale;
          } else {
            scale = yScale;
            this.workspaceHeight = availableHeight;
            this.workspaceWidth = 1280 * scale;
          }

        } else {

          // portrait

          let availableWidth = window.innerWidth - 50;
          let availableHeight = window.innerHeight - 100;
     
          const xScale = availableWidth / 768;
          const yScale = availableHeight / 1280;
        
          if (xScale < yScale) {
            scale = xScale;
            this.workspaceWidth = availableWidth;
            this.workspaceHeight = 1280 * scale;
          } else {
            scale = yScale;
            this.workspaceHeight = availableHeight;
            this.workspaceWidth = 768 * scale;
          }

        }

        this.tick.width = 128 * scale;
        this.tick.top = this.workspaceHeight - this.tick.width;
        this.tick.left = this.workspaceWidth - this.tick.width;

      },

      replayAudio() {
        this.$refs.exercisePlayer.replayAudio();
      },

      showAnswer() {
        this.$refs.exercisePlayer.toggleHelp();
      },

      showTick(correct) {
        this.question.correct = correct;
        this.question.showTick = true;
      },

    },

  }
</script>

<style scoped>

  .disable-dbl-tap-zoom {
    touch-action: manipulation;
  }

  .sidebar {
    height: 100%;
    width: 100px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #f5f5f5;
    overflow-x: hidden;
    padding-top: 16px;
    text-align: center;
  }

  .bottombar {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    overflow-x: hidden;
    padding-top: 16px;
    text-align: left;
  }

</style>